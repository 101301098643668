import "./App.css";


import Loading from "./components/loading/Loading"

import { lazy, Suspense } from "react";

import { BrowserRouter as Main, Route, Routes } from "react-router-dom";

import Homepage from "./pages/Homepage";

import Error from "./pages/error-page/Error";

const About = lazy(() => import("./pages/about/About"));

const ServicesPage = lazy(() => import("./pages/services-page/Services_page"));

const CustomWebsiteDesign = lazy(() =>
  import("./pages/sub-categories-1/custom-website-design/CustomWebsiteDesign")
);

const WordpressWebsiteDesign = lazy(() =>
  import(
    "./pages/sub-categories-1/wordpress-website-design/WordpressWebsiteDesign"
  )
);

const SeoWebsite = lazy(() =>
  import("./pages/sub-categories-1/SEO-website/SeoWebsite")
);

const EcommerceWebsite = lazy(() =>
  import("./pages/sub-categories-1/e-commerce-website-design/EcommerceWebsite")
);

const SocialMediaMarketingPage = lazy(() =>
  import("./pages/social-media-marketing-page/SocialMediaMarketingPage")
);

const SocialMediaAdvertising = lazy(() =>
  import(
    "./pages/sub-categories-2/social-media-advertising/SocialMediaAdvertising"
  )
);

const SocialMediaBranding = lazy(() =>
  import("./pages/sub-categories-2/social-media-branding/SocialMediaBranding")
);

const SocialMediaManagement = lazy(() =>
  import(
    "./pages/sub-categories-2/social-media-management/SocialMediaManagement"
  )
);

const GraphicDesign = lazy(() =>
  import("./pages/graphic-designing-page/GraphicDesign")
);

const DesignStationary = lazy(() =>
  import("./pages/sub-categories-3/Design-Stationary/DesignStationary")
);

const LogoDesign = lazy(() =>
  import("./pages/sub-categories-3/Logo-Design/LogoDesign")
);

const SocialMediaPostDesign = lazy(() =>
  import(
    "./pages/sub-categories-3/Social-Media-Post-Design/SocialMediaPostDesign"
  )
);

const UiUxDesign = lazy(() =>
  import("./pages/sub-categories-3/UI-UX-Design/UiUxDesign")
);

const BookCoverDesign = lazy(() =>
  import("./pages/sub-categories-3/Book-cover-design/BookCoverDesign")
);

const VideoMarketingServicesPage = lazy(() =>
  import("./pages/video-marketing-services-page/VideoMarketingServicesPage")
);

const AnimatedDesign = lazy(() =>
  import("./pages/sub-categories-4/animated-design/AnimatedDesign")
);

const AnimatedProductPromoAds = lazy(() =>
  import(
    "./pages/sub-categories-4/animated-product-promo-ads/AnimatedProductPromoAds"
  )
);

const ExplainerAnimations = lazy(() =>
  import("./pages/sub-categories-4/explainer-animations/ExplainerAnimations")
);

const SocialMediaReels = lazy(() =>
  import("./pages/sub-categories-4/social-media-reels/SocialMediaReels")
);

const Contact = lazy(() => import("./pages/contact-us-page/Contact"));

const Portfolio = lazy(() => import("./pages/portfolio/Portfolio"));

const Arlington = lazy(() => import("./pages/cities/arlington/Arlington"));

const Atlanta = lazy(() => import("./pages/cities/atlanta/Atlanta"));

const Austin = lazy(() => import("./pages/cities/austin/Austin"));

const Balltimore = lazy(() => import("./pages/cities/baltimore/Balltimore"));

const Chicago = lazy(() => import("./pages/cities/chicago/Chicago"));

const Cleveland = lazy(() => import("./pages/cities/cleveland/Cleveland"));

const Denver = lazy(() => import("./pages/cities/denver/Denver"));

const Kansas = lazy(() => import("./pages/cities/kansas/Kansas"));

const Miami = lazy(() => import("./pages/cities/miami/Miami"));

const SanFrancisco = lazy(() =>
  import("./pages/cities/sanfrancisco/SanFrancisco")
);

const Dallas = lazy(() => import("./pages/cities/dallas/Dallas"));

const Houston = lazy(() => import("./pages/cities/houston/Houston"));

const LosAngeles = lazy(() => import("./pages/cities/losangeles/LosAngeles"));

const LasVegas = lazy(() => import("./pages/cities/lasvegas/LasVegas"));

const NewYork = lazy(() => import("./pages/cities/newyork/NewYork"));

const Blog = lazy(() => import("./blog/Blog"));

const BlogNewPage = lazy(() =>
  import("./components/blog-page-components/Blog-New-Page/BlogNewPage")
);



function App() {

  return (
    <>
      <Main>
        <Routes>
          <Route exact path="/" element={<Homepage />} />

          <Route
            exact
            path="/about"
            element={
              <Suspense
                fallback={
                  <Loading />
                }
              >
                <About />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/websiteDesignAndDevelopment"
            element={
              <Suspense
                fallback={
                  <Loading />
                }
              >
                <ServicesPage />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/customwebsitedesign"
            element={
              <Suspense fallback={<Loading />}>
                <CustomWebsiteDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/wordpresswebsitedsign"
            element={
              <Suspense fallback={<Loading />}>
                <WordpressWebsiteDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/seowebsite"
            element={
              <Suspense fallback={<Loading />}>
                <SeoWebsite />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/ecommercewebsite"
            element={
              <Suspense fallback={<Loading />}>
                <EcommerceWebsite />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/socialmediamarketing"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaMarketingPage />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/socialmediamarketing/socialmediaadvertising"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaAdvertising />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/socialmediamarketing/socialmediabranding"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaBranding />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/socialmediamarketing/socialmediamanagement"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaManagement />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign"
            element={
              <Suspense fallback={<Loading />}>
                <GraphicDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign/designstationary"
            element={
              <Suspense fallback={<Loading />}>
                <DesignStationary />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign/logodesign"
            element={
              <Suspense fallback={<Loading />}>
                <LogoDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign/socialmediapostdesign"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaPostDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign/uiuxdesign"
            element={
              <Suspense fallback={<Loading />}>
                <UiUxDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/graphicdesign/bookcoverdesign"
            element={
              <Suspense fallback={<Loading />}>
                <BookCoverDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/videomarketingservices"
            element={
              <Suspense fallback={<Loading />}>
                <VideoMarketingServicesPage />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/videomarketingservices/animateddesign"
            element={
              <Suspense fallback={<Loading />}>
                <AnimatedDesign />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/videomarketingservices/animatedproductpromoads"
            element={
              <Suspense fallback={<Loading />}>
                <AnimatedProductPromoAds />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/videomarketingservices/explaineranimations"
            element={
              <Suspense fallback={<Loading />}>
                <ExplainerAnimations />
              </Suspense>
            }
          />

          <Route
            exact
            path="/services/videomarketingservices/socialmediareels"
            element={
              <Suspense fallback={<Loading />}>
                <SocialMediaReels />
              </Suspense>
            }
          />

          <Route
            exact
            path="/contactus"
            element={
              <Suspense fallback={<Loading />}>
                <Contact />
              </Suspense>
            }
          />

          <Route
            exact
            path="/portfolio"
            element={
              <Suspense fallback={<Loading />}>
                <Portfolio />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/arlington"
            element={
              <Suspense fallback={<Loading />}>
                <Arlington />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/atlanta"
            element={
              <Suspense fallback={<Loading />}>
                <Atlanta />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/austin"
            element={
              <Suspense fallback={<Loading />}>
                <Austin />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/baltimore"
            element={
              <Suspense fallback={<Loading />}>
                <Balltimore />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/chicago"
            element={
              <Suspense fallback={<Loading />}>
                <Chicago />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/cleveland"
            element={
              <Suspense fallback={<Loading />}>
                <Cleveland />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/denver"
            element={
              <Suspense fallback={<Loading />}>
                <Denver />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/kansas"
            element={
              <Suspense fallback={<Loading />}>
                <Kansas />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/miami"
            element={
              <Suspense fallback={<Loading />}>
                <Miami />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/sanfrancisco"
            element={
              <Suspense fallback={<Loading />}>
                <SanFrancisco />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/dallas"
            element={
              <Suspense fallback={<Loading />}>
                <Dallas />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/houston"
            element={
              <Suspense fallback={<Loading />}>
                <Houston />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/losangeles"
            element={
              <Suspense fallback={<Loading />}>
                <LosAngeles />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/lasvegas"
            element={
              <Suspense fallback={<Loading />}>
                <LasVegas />
              </Suspense>
            }
          />

          <Route
            exact
            path="/cities/newyork"
            element={
              <Suspense fallback={<Loading />}>
                <NewYork />
              </Suspense>
            }
          />

          <Route
            exact
            path="/blog"
            element={
              <Suspense fallback={<Loading />}>
                <Blog />
              </Suspense>
            }
          />

          <Route
            // path="/blogNewPage"
            path = "blog-article"
            element={
              <Suspense fallback={<Loading />}>
                <BlogNewPage />
              </Suspense>
            }
          />

          <Route exact path="/*" element={<Error />} />
        </Routes>
      </Main>
    </>
  );
}
export default App;
