import { InfinitySpin } from "react-loader-spinner";

export default function Loading() {

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center",     // Center vertically
      }}
    >
      <InfinitySpin
        height={200}
        width={250}
        color="#612d91"
        ariaLabel="loading"
      />
    </div>
  );
}
