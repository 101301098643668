import React from "react";

import "./Summary.css";

export default function Summary() {
  return (
    <section className="summary-main-container">
      <h1>DIGITAL MARKETING AS A SERVICE?</h1>

      <br />

      <p>
        It is a growing field in today's market, offering small and running
        businesses the opportunity to reach their target audiences and promote
        their products or services, from digital marketing SEO, social media
        campaigns, search engine optimization (SEO) activities such as link
        building and content creation, pay-per-click advertising management down
        to more traditional forms of marketing like email blasts.
      </p>

      <br />

      <div className="summary-main-container-2">
        <div className="summary-main-container-2a">
          <p>
            One of the most important parts of digital marketing is SEO, which
            uses specific techniques, like keyword research that helps a website
            appear at the top of organic search engines for relevant queries and
            can drive website traffic and make the company’s website evident.
            There are many website design companies in the USA, and Interac8 is
            one of the digital marketing agencies which also deals with social
            media marketing in the USA. Digital marketers analyze consumer
            behavior online with technologies such as web analytics for customer
            acquisition strategies that generate qualified leads at an
            advantageous rate when compared to other platforms. Every other
            business wants to stand out and reach the top, it can only be
            possible with different social media strategies, It is defined by a
            set of different methods that combine in a way that allows consumers
            to engage with some entity, like a business. Digital service
            includes, “Online shopping”, and the experience you have while
            engaging with that service is what becomes the digital experience.
            Like, there are many platforms where we can have a digital
            experience.
          </p>
        </div>

        <div className="summary-main-container-2b">
          <picture>

            <source
              media="(max-width: 320px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_260/v1692365079/HomePagePictures/DigitalMarketingAsAService/summary_njld0m.webp 260w"
            />

            <source
              media="(max-width: 375px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_325/v1692365079/HomePagePictures/DigitalMarketingAsAService/summary_njld0m.webp 315w"
            />

            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_325/v1692365079/HomePagePictures/DigitalMarketingAsAService/summary_njld0m.webp 325w"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692365079/HomePagePictures/DigitalMarketingAsAService/summary_njld0m.webp"
              alt="Digital Marketing Agency In The U.S."
            />
          </picture>
        </div>
      </div>
    </section>
  );
}
