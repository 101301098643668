import React, { useState, useEffect } from "react";

import "./Services1.css";

import { useInView } from "react-intersection-observer";

export default function Services1() {
  const [visionAndMission, setvisionAndMission] = useState(true);

  const showVisionAndMission = () => {
    setvisionAndMission(false);
  };

  const hideVisionAndMission = () => {
    setvisionAndMission(true);
  };

  const [solution, setSolution] = useState(true);

  const showSolution = () => {
    setSolution(false);
  };

  const hideSolution = () => {
    setSolution(true);
  };

  const [design, setDesign] = useState(true);

  const showDesign = () => {
    setDesign(false);
  };

  const hideDesign = () => {
    setDesign(true);
  };

  const [branding, setBranding] = useState(true);

  const showBranding = () => {
    setBranding(false);
  };

  const hideBranding = () => {
    setBranding(true);
  };

  const [marketing, setMarketing] = useState(true);

  const showMarketing = () => {
    setMarketing(false);
  };

  const hideMarketing = () => {
    setMarketing(true);
  };

  const [sales, setSales] = useState(true);

  const showSales = () => {
    setSales(false);
  };

  const hideSales = () => {
    setSales(true);
  };

  // useEffect(() => {
  //   // Preload background images
  //   const preloadImages = [
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435171/HomePagePictures/OurMission/Vision-Mission-Light_vgt77p.webp",
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435515/HomePagePictures/OurMission/solution_hwsd33.webp",
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435920/HomePagePictures/OurMission/design_ztmpdj.webp",
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436605/HomePagePictures/OurMission/marketing_dtepd2.webp",
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436828/HomePagePictures/OurMission/sales_vhhmbb.webp",
  //     "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436253/HomePagePictures/OurMission/background_pu2kfm.webp",
  //   ];

  //   preloadImages.forEach((image) => {
  //     const link = document.createElement("link");
  //     link.rel = "preload";
  //     link.href = image;
  //     link.as = "image";
  //     document.head.appendChild(link);
  //   });

  //   // Clean up the added link elements when the component unmounts
  //   return () => {
  //     preloadImages.forEach((image) => {
  //       const link = document.querySelector(
  //         `link[href="${image}"][as="image"]`
  //       );
  //       if (link) {
  //         document.head.removeChild(link);
  //       }
  //     });
  //   };
  // }, []);

  const [refServices, inViewRefServices] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    const preloadImages = [
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435171/HomePagePictures/OurMission/Vision-Mission-Light_vgt77p.webp",
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435515/HomePagePictures/OurMission/solution_hwsd33.webp",
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692435920/HomePagePictures/OurMission/design_ztmpdj.webp",
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436253/HomePagePictures/OurMission/background_pu2kfm.webp",
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436605/HomePagePictures/OurMission/marketing_dtepd2.webp",
      "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692436828/HomePagePictures/OurMission/sales_vhhmbb.webp",
    ];

    if (inViewRefServices && !backgroundImageLoaded[0]) {
      preloadImages.forEach((image , index) => {
        const picture = new Image();
        picture.src = image;
        picture.onload = () => {
          setBackgroundImageLoaded(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = true;
            return updatedState;
          });
        };
      });
    }
  }, [inViewRefServices, backgroundImageLoaded]);

  return (
    <div className="main-services-container" ref={refServices}>
      <h1 className="text-3xl font-bold text-left font-montserrat mb-2">Our mission</h1>

      <p className="main-services-container-description">
        Our aim as a digital marketing agency is to empower cutting-edge
        strategies and innovative solutions for your business.
      </p>

      <br />

      <div className="services1-card-container">
        <div className="services1-card-container-one">
          <div
            className={`vision-mission ${backgroundImageLoaded[0] ? "vision-mission-background" : ""}`}
            onMouseEnter={showVisionAndMission}
            onMouseLeave={hideVisionAndMission}
          >
            {visionAndMission ? (
              <h3>Vision & Mission</h3>
            ) : (
              <p>
                The digital marketing agency has set specific goals to meet
                emerging trends in its market. Its mission is to reach a larger
                client base in the USA with innovative ideas. With this target,
                the team works towards achieving its end purpose of becoming an
                industry leader.
              </p>
            )}
          </div>

          <div
            className={`solution ${backgroundImageLoaded[1] ? "solution-background" : ""}`}
            onMouseEnter={showSolution}
            onMouseLeave={hideSolution}
          >
            {solution ? (
              <h3>Solution</h3>
            ) : (
              <p>
                We troubleshoot any digital design issues before the process is
                started and diagnose pre-task problems.
              </p>
            )}
          </div>

          <div
            className={`design ${backgroundImageLoaded[2] ? "design-background" : ""}`}
            onMouseEnter={showDesign}
            onMouseLeave={hideDesign}
          >
            {design ? (
              <h3>Design</h3>
            ) : (
              <p>
                We ensure your design is crafted into a distinctive aesthetic,
                establishing it as a unique brand and earning the acknowledgment
                it merits.
              </p>
            )}
          </div>
        </div>

        <div className="services1-card-container-two">
          <div
            className={`branding ${backgroundImageLoaded[3] ? "branding-background" : ""}`}
            onMouseEnter={showBranding}
            onMouseLeave={hideBranding}
          >
            {branding ? (
              <h3>Branding</h3>
            ) : (
              <p>
                We ensure that your design undergoes a branding process in our
                digital marketing company to cultivate its distinctiveness,
                ensuring it is recognized as a brand and receives the
                appropriate acclaim.
              </p>
            )}
          </div>

          <div
            className={`marketing ${backgroundImageLoaded[4] ? "marketing-background" : ""}`}
            onMouseEnter={showMarketing}
            onMouseLeave={hideMarketing}
          >
            {marketing ? (
              <h3>Marketing</h3>
            ) : (
              <p>
                Through proficient digital marketing as a service, endless
                possibilities arise, allowing you to strategically reach your
                desired audience and guarantee your ultimate satisfaction.
              </p>
            )}
          </div>
          <div
            className={`sales ${backgroundImageLoaded[5] ? "sales-background" : ""}`}
            onMouseEnter={showSales}
            onMouseLeave={hideSales}
          >
            {sales ? (
              <h3>Sales</h3>
            ) : (
              <p>
                All these elements converge on what is of utmost importance: the
                revenue generated from sales, as each component is essential to
                compose a wholesome recipe for success.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
