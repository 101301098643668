import React , {useState , useEffect} from "react";

import underline from "./Line.svg";

import Ball from "./Ball.svg"

import { useInView } from "react-intersection-observer";

export default function CaseStudy() {

  // Setting the counter for business development
  const [refBusinessDevelopment, inViewBusinessDevelopment] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [BusinessDevelopmentCounter, setBusinessDevelopmentCounter] = useState(0);

  useEffect(() => {
    if (inViewBusinessDevelopment) {
      const targetCount = 52;
      const duration = 1000;
      const delay = 10;
      const steps = duration / delay;
      const increment = targetCount / steps;

      let currentCount = 0;
      let counter = setInterval(() => {
        currentCount += increment;
        setBusinessDevelopmentCounter(Math.round(currentCount));

        if (currentCount >= targetCount) {
            setBusinessDevelopmentCounter(targetCount);
          clearInterval(counter);
        }
      }, delay);

      return () => {
        clearInterval(counter);
      };
    }
  }, [inViewBusinessDevelopment]);
  // Setting the counter for business development

  // Setting the counter for clients
  const [refClients , inViewClients] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [clients, setClients] = useState(0);
 
  useEffect(() => {
    const targetCount = 38;
    const duration = 1000; 
    const delay = 10; 
    const steps = duration / delay;
    const increment = targetCount / steps;

    let currentCount = 0;
    let counter = setInterval(() => {
      currentCount += increment;
      setClients(Math.round(currentCount));

      if (currentCount >= targetCount) {
        setClients(targetCount);
        clearInterval(counter);
      }
    }, delay);

    return () => {
      clearInterval(counter);
    };
  }, [inViewClients]);
  // Setting the counter for clients

  // Setting the counter for coffee
  const [refCoffee, inViewCoffee] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [coffee, setCoffee] = useState(0);

  useEffect(() => {
    const targetCount = 79;
    const duration = 1000; 
    const delay = 10; 
    const steps = duration / delay;
    const increment = targetCount / steps;

    let currentCount = 0;
    let counter = setInterval(() => {
      currentCount += increment;
      setCoffee(Math.round(currentCount));

      if (currentCount >= targetCount) {
        setCoffee(targetCount);
        clearInterval(counter);
      }
    }, delay);

    return () => {
      clearInterval(counter);
    };
  }, [inViewCoffee]);
  // Setting the counter for coffee

  return (
    <div className="px-12 my-8 lg:px-32 relative lg:my-8">

      <div className="border-t-2 border-b-2 border-[#612D91] py-4">

        <div className=" flex flex-col items-center">
          <h1 className="text-[#612D91] text-center text-sm">CASE STUDY</h1>

          <h1 className="text-[#612D91] text-center text-lg font-bold">
            EASY IMPORT
          </h1>

          <img src={underline} className="-mt-3 w-64 h-auto" />
        </div>

        <div className="md:flex md:justify-center md:gap-8">
          <div className="mt-4">
            <h1 ref={refBusinessDevelopment} className="text-center text-3xl font-bold">{BusinessDevelopmentCounter}</h1>

            <h6 className="text-center text-base text-[#612D91] font-semibold md:w-32">
              Business Development
            </h6>
          </div>

          <div className="mt-4">
            <h1 ref={refClients} className="text-center text-3xl font-bold">{clients}</h1>

            <h6 className="text-center text-base text-[#612D91] font-semibold">
              Clients
            </h6>
          </div>

          <div className="mt-4">
            <h1 ref={refCoffee} className="text-center text-3xl font-bold">{coffee}</h1>

            <h6 className="text-center text-base text-[#612D91] font-semibold">
              Coffee's Drank
            </h6>
          </div>
        </div>

        <div className="hidden md:block md:w-16 lg:w-20 md:absolute md:top-[12.5%] md:right-5 lg:right-10 ">
            <img src={Ball} />
        </div>

      </div>
    </div>
  );
}
