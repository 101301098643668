import React from 'react'

import Navbar from '../../components/genral_components/Navbar/Navbar';

import "./Error.css"

import Footer from '../../components/genral_components/Footer/Footer';

export default function Error() {
  return (
    <div className='error-main-container'>

      <Navbar/>

      <div className='error-main-container-not-found'>

      </div>

      <Footer/>

    </div>
  )
}
