import React from "react";

import "./Footer.css";

import { FaEnvelope } from "react-icons/fa";
import { AiOutlinePhone } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-1">
        <div className="about-company">

          <picture>

            <source
              media="(max-width: 320px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_80,h_32/v1692787407/Footer/logo_ib0kcl.webp 80w"
            />

            <source
              media="(max-width: 375px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_95,h_40/v1692787407/Footer/logo_ib0kcl.webp 95w"
            />

            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_120/v1692787407/Footer/logo_ib0kcl.webp 120w"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692787407/Footer/logo_ib0kcl.webp"
              alt="Digital Marketing Agency In U.S."
            />

          </picture>

          <p>
            With years of experience and expertise, our team of creatives,
            digital marketing consultants, smarter strategists, and developers
            is dedicated to providing ultimate digital marketing solutions.
          </p>
        </div>

        <div className="cities">
          <div className="cith-heading">
            <h3>Cities</h3>
          </div>

          <div className="all-cities">
            <div>
              <p>
                <Link to="/cities/arlington">Arlington</Link>
              </p>

              <p>
                <Link to="/cities/atlanta">Atlanta</Link>
              </p>

              <p>
                <Link to="/cities/austin">Austin</Link>
              </p>

              <p>
                <Link to="/cities/baltimore">Baltimore</Link>
              </p>

              <p>
                <Link to="/cities/chicago">Chicago</Link>
              </p>
            </div>

            <div>
              <p>
                <Link to="/cities/cleveland">Cleveland</Link>
              </p>
              <p>
                <Link to="/cities/denver">Denver</Link>
              </p>
              <p>
                <Link to="/cities/kansas">Kansas City</Link>
              </p>
              <p>
                <Link to="/cities/miami">Miami</Link>
              </p>
              <p>
                <Link to="/cities/sanfrancisco">San Francisco</Link>
              </p>
            </div>

            <div>
              <p>
                <Link to="/cities/dallas">Dallas</Link>
              </p>
              <p>
                <Link to="/cities/houston">Houston</Link>
              </p>
              <p>
                <Link to="/cities/losangeles">Los Angeles</Link>
              </p>
              <p>
                <Link to="/cities/lasvegas">Las Vegas</Link>
              </p>
              <p>
                <Link to="/cities/newyork">New York City</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="enquiry">
          <div className="icons-container">
            <a
              href="https://www.facebook.com/interac.8/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size={20} color="#612D91"></FaFacebook>
            </a>

            <a
              href="https://www.instagram.com/interac.8/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={20} color="#612D91" />
            </a>

            <a
              href="https://www.linkedin.com/company/interac8/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin size={20} color="#612D91" />
            </a>
          </div>

          <div className="enquiry2">
            <Link className="email" to="mailto:info@interac8digital.com">
              <FaEnvelope size={18} color="#612D91" />
              <p>info@interac8digital.com</p>
            </Link>

            <Link className="phone" to="tel:8882192787">
              <AiOutlinePhone size={18} color="#612D91" />
              <p>+ 1 (414) 109 2036</p>
            </Link>

            <Link className="location" to="/contactus">
              <FiMapPin size={18} color="#612D91" />
              <p>4534 W North Ave, Milwaukee, WI 53208, USA</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-2">
        <p>Copyright 2023 © Interac8Digital All rights reserved.</p>
      </div>
    </div>
  );
}
