import React from "react";

import "./Ourwork.css";

import { useNavigate } from "react-router-dom";

export default function Ourwork() {
  const navigate = useNavigate();

  return (
    <div className="our-work-container">
      <div className="our-work-container-one">
        <br />
        <br />

        <picture>
          <source
            media="(max-width: 320px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_320,h_26/v1692437909/HomePagePictures/OurWork/our_work_1_1_d1penw.webp 320w"
          />

          <source
            media="(max-width: 375px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_375,h_31/v1692437909/HomePagePictures/OurWork/our_work_1_1_d1penw.webp 375w"
          />

          <source
            media="(max-width: 450px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_425,h_35/v1692437909/HomePagePictures/OurWork/our_work_1_1_d1penw.webp 425w"
          />

          <img
            src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437909/HomePagePictures/OurWork/our_work_1_1_d1penw.webp"
            style={{ width: "100%", height: "100%", zIndex: -20 }}
            alt="portfolio for Digital Marketing Agency In The U.S."
          />
        </picture>

        <br />

        <div className="our-work">
          <span className="our">Portfolio</span>
        </div>

        <br />

        <p>
          With a proven track record, our digital marketing agency has
          successfully delivered impactful projects for clients ranging from
          startups to established brands, driving tangible business growth and
          measurable results.
        </p>
      </div>

      <br />
      <br />
      <br />

      <div className="our-work-cards">
        <picture style={{ width: "100%", height: "100%", zIndex: -20 }}>

          <source
            media="(max-width: 320px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_320,h_150/v1692437910/HomePagePictures/OurWork/our_work_2_1_yolwx5.webp 320w"
          />

          <source
            media="(max-width: 375px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_375,h_176/v1692437910/HomePagePictures/OurWork/our_work_2_1_yolwx5.webp 375w"
          />

          <source
            media="(max-width: 450px)"
            srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_425,h_199/v1692437910/HomePagePictures/OurWork/our_work_2_1_yolwx5.webp 425w"
          />

          <img
            src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437910/HomePagePictures/OurWork/our_work_2_1_yolwx5.webp"
            style={{ width: "100%", height: "100%", zIndex: -20 }}
            alt="portfolio"
          />

        </picture>

        <button
          onClick={() => {
            navigate("/portfolio");
          }}
        >
          View Portfolio
        </button>
      </div>
    </div>
  );
}
