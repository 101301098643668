import React from "react";
import Slider from "react-slick";
import "./Carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import StarRating from "../stars/StarRating";

export default function Carousel(prop) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        marginTop: "3%",
        paddingLeft: "50px",
        paddingRight: "50px",
      }}
    >
      <Slider {...settings}>
        <div className="carousel-container-item slide">
          <h1>{prop.nameOne} </h1>

          {/* <br />

            <StarRating rating={5} />

            <br /> */}

          <p>{prop.reviewOne}</p>
        </div>

        <div className="carousel-container-item slide">
          <h1>{prop.nameTwo}</h1>

          {/* <br />

            <StarRating rating={4} />

            <br /> */}

          <p>{prop.reviewTwo}</p>
        </div>

        <div className="carousel-container-item slide">
          <h1>{prop.nameThree}</h1>

          {/* <br />

            <StarRating rating={5} />

            <br /> */}

          <p>{prop.reviewThree}</p>
        </div>
      </Slider>
    </div>
  );
}

Carousel.defaultProps = {

  nameOne: "LILY ANDERSON",

  reviewOne:
    "Wanted a distinctive logo that depicts the company's values and standards and can communicate the message effectively, interac8 crafted it effortlessly making my business appear prominent and recognizable!",

  nameTwo: "RYAN SMITH",

  reviewTwo:
    "Was struggling to grow my brand, and all I needed was efficacious marketing, Interac8 helped me give my brand an edge over competitors, through quality content and spectacular visuals!",

  nameThree: "GAVIN CLARK",

  reviewThree:
    "Working with interac8 was an amazing experience, provided me with their best services, results were bang on with exceptional sales!",

};
