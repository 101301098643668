import React from "react";

import "./Pricing2.css";

import { TbCircleCheckFilled } from "react-icons/tb";

import { Link } from "react-router-dom";

export default function Pricing2(prop) {
  return (
    <div className="pricing-two-main-container">
      {Array(prop.numberOfCards)
        .fill()
        .map((_, index) => (
          <div
            className={`${
              index === prop.whichCardIsRecommended
                ? "pricing-two-main-container-card recommended"
                : "pricing-two-main-container-card"
            }`}
          >
            <h1>{prop.headings[index]}</h1>

            <h4>{prop.prices[index]}</h4>

            <div className="pricing-two-main-container-card-all-pointer">
              {Array(prop.cardPointers[index].length)
                .fill()
                .map((_, indexInside) => (
                  <div className="pricing-two-main-container-card-pointer">
                    <span>
                      <TbCircleCheckFilled size={24} className="card-pointer" />
                    </span>
                    <span>{prop.cardPointers[index][indexInside]}</span>
                  </div>
                ))}
            </div>

            <Link
              to="/contactus"
              state={{
                mainCategory: prop.category,
                subCategory: prop.headings[index],
                price: prop.prices[index]
              }}
            >
              <button>Choose Plan</button>
            </Link>
          </div>
        ))}
    </div>
  );
}
