import React, { useState, useRef, useEffect } from "react";

import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";

import { Link } from "react-router-dom";

import { AiOutlineCaretDown } from "react-icons/ai";

import { CgWebsite } from "react-icons/cg";

import { SiWordpress } from "react-icons/si";

import { TbSeo } from "react-icons/tb";

import { HiOutlineShoppingCart } from "react-icons/hi";

import { FaUsers } from "react-icons/fa";

import { FaAdversal } from "react-icons/fa";

import { AiTwotoneShop } from "react-icons/ai";

import { GiSecretBook } from "react-icons/gi";

import { SiInteractiondesignfoundation } from "react-icons/si";

import { RxIconjarLogo } from "react-icons/rx";

import { MdCampaign } from "react-icons/md";

import { IoIosColorPalette } from "react-icons/io";

import { AiFillRocket } from "react-icons/ai";

import { TbBrandFlightradar24 } from "react-icons/tb";

import { DiCodeigniter } from "react-icons/di";

import { BsCameraReelsFill } from "react-icons/bs";

export default function Navbar() {
  const navRef = useRef();

  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const [showDropDown, setShowDropDown] = useState(false);

  const openDropDown = () => {
    setShowDropDown(true);
  };

  const closeDropDown = () => {
    setShowDropDown(false);
  };

  // From here, we try to make navbar responsive

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      // Code to handle the window resize event
      // You can access the updated dimensions here
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showFirstDropDown, setShowFirstDropDown] = useState(false);

  const [showSecondDropDown, setShowSecondDropDown] = useState(false);

  const [showThirdDropDown, setShowThirdDropDown] = useState(false);

  const [showFourthDropDown, setShowFourthDropDown] = useState(false);

  return (
    <div className="navbar-main-container-one">
      <header>
        <div className="logo">
          
          <picture>

            <source
              media="(max-width: 450px)"
              srcset="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_150/v1692787149/HomePagePictures/Logo04_gjazx5.webp 150w"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692787149/HomePagePictures/Logo04_gjazx5.webp"
              alt="Interac8Digital Logo"
            />
            
          </picture>

        </div>

        <nav ref={navRef}>
          <Link to="/" className="home">
            Home
          </Link>

          <Link to="/about" className="about">
            About
          </Link>

          <Link to="/blog" className="about">
            Blogs
          </Link>

          <Link className="services" onMouseEnter={openDropDown}>
            Services
            {!(screenWidth < 1024) && (
              <AiOutlineCaretDown className="drop-down" />
            )}
            {screenWidth < 1024 && (
              <div className="all-mobile-categories">
                <div className="mobile-category-one">
                  <h4>
                    <Link
                      to="/services/websiteDesignAndDevelopment"
                      className="mobile-category-one-heading"
                    >
                      Website Design & Development
                    </Link>

                    <AiOutlineCaretDown
                      className="drop-down"
                      onClick={() => {
                        setShowFirstDropDown(!showFirstDropDown);
                      }}
                    />
                  </h4>

                  <div
                    className={`mobile-category-one-sub-categories ${
                      showFirstDropDown ? "open" : ""
                    } slide-first-dropdown-from-top`}
                  >
                    <Link
                      to="/services/customwebsitedesign"
                      className="sub-category-one"
                    >
                      <CgWebsite color="#612d91" />

                      <p>Custom Website Design</p>
                    </Link>

                    <Link
                      to="/services/wordpresswebsitedsign"
                      className="sub-category-two"
                    >
                      <SiWordpress color="#612d91" />

                      <p>WordPress Website Design</p>
                    </Link>

                    <Link
                      to="/services/seowebsite"
                      className="sub-category-three"
                    >
                      <TbSeo color="#612d91" />

                      <p>SEO Website</p>
                    </Link>

                    <Link
                      to="/services/ecommercewebsite"
                      className="sub-category-four"
                    >
                      <HiOutlineShoppingCart color="#612d91" />

                      <p>E-commerce website design</p>
                    </Link>
                  </div>
                </div>

                <div className="mobile-category-two">
                  <h4>
                    <Link
                      to="/services/socialmediamarketing"
                      className="mobile-category-one-heading"
                    >
                      Social Media Marketing Agency
                    </Link>

                    <AiOutlineCaretDown
                      className="drop-down"
                      onClick={() => {
                        setShowSecondDropDown(!showSecondDropDown);
                      }}
                    />
                  </h4>

                  <div
                    className={`mobile-category-one-sub-categories ${
                      showSecondDropDown ? "open" : ""
                    } slide-first-dropdown-from-top`}
                  >
                    <Link
                      to="/services/socialmediamarketing/socialmediamanagement"
                      className="sub-category-five"
                    >
                      <FaUsers color="#612d91" />

                      <p>Social Media Management</p>
                    </Link>

                    <Link
                      to="/services/socialmediamarketing/socialmediaadvertising"
                      className="sub-category-six"
                    >
                      <FaAdversal color="#612d91" />

                      <p>Social Media Advertising</p>
                    </Link>

                    <Link
                      to="/services/socialmediamarketing/socialmediabranding"
                      className="sub-category-seven"
                    >
                      <AiTwotoneShop color="#612d91" />

                      <p>Social Media Branding</p>
                    </Link>
                  </div>
                </div>

                <div className="mobile-category-three">
                  <h4>
                    <Link
                      to="/services/socialmediamarketing"
                      className="mobile-category-one-heading"
                    >
                      Graphic Design Services
                    </Link>

                    <AiOutlineCaretDown
                      className="drop-down"
                      onClick={() => {
                        setShowThirdDropDown(!showThirdDropDown);
                      }}
                    />
                  </h4>

                  <div
                    className={`mobile-category-one-sub-categories ${
                      showThirdDropDown ? "open" : ""
                    } slide-first-dropdown-from-top`}
                  >
                    <Link
                      to="/services/graphicdesign/bookcoverdesign"
                      className="sub-category-eight"
                    >
                      <GiSecretBook color="#612d91" />

                      <p>Book Cover Design</p>
                    </Link>

                    <Link
                      to="/services/graphicdesign/designstationary"
                      className="sub-category-nine"
                    >
                      <SiInteractiondesignfoundation color="#612d91" />

                      <p>Stationary Design</p>
                    </Link>

                    <Link
                      to="/services/graphicdesign/logodesign"
                      className="sub-category-ten"
                    >
                      <RxIconjarLogo color="#612d91" />

                      <p>Logo Design</p>
                    </Link>

                    <Link
                      to="/services/graphicdesign/socialmediapostdesign"
                      className="sub-category-eleven"
                    >
                      <MdCampaign color="#612d91" />

                      <p>Social Media Post Design</p>
                    </Link>

                    <Link
                      to="/services/graphicdesign/uiuxdesign"
                      className="sub-category-twelve"
                    >
                      <IoIosColorPalette color="#612d91" />

                      <p>UI-UX Design</p>
                    </Link>
                  </div>
                </div>

                <div className="mobile-category-four">
                  <h4>
                    <Link
                      to="/services/videomarketingservices"
                      className="mobile-category-one-heading"
                    >
                      Video Animation Design
                    </Link>

                    <AiOutlineCaretDown
                      className="drop-down"
                      onClick={() => {
                        setShowFourthDropDown(!showFourthDropDown);
                      }}
                    />
                  </h4>

                  <div
                    className={`mobile-category-one-sub-categories ${
                      showFourthDropDown ? "open" : ""
                    } slide-first-dropdown-from-top`}
                  >
                    <Link
                      to="/services/videomarketingservices/animateddesign"
                      className="sub-category-thirteen"
                    >
                      <AiFillRocket color="#612d91" />

                      <p>Animated Design</p>
                    </Link>

                    <Link
                      to="/services/videomarketingservices/animatedproductpromoads"
                      className="sub-category-fourteen"
                    >
                      <TbBrandFlightradar24 color="#612d91" />

                      <p>Promo Ads</p>
                    </Link>

                    <Link
                      to="/services/videomarketingservices/explaineranimations"
                      className="sub-category-fifteen"
                    >
                      <DiCodeigniter color="#612d91" />

                      <p>Explainer Animations</p>
                    </Link>

                    <Link
                      to="/services/videomarketingservices/socialmediareels"
                      className="sub-category-fifteen"
                    >
                      <BsCameraReelsFill color="#612d91" />

                      <p>Social Media Reels</p>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Link>

          <Link to="/portfolio" className="portfolio">
            Portfolio
          </Link>

          <Link to="/contactus" className="contact">
            Contact
          </Link>

          <button className="nav-btn nav-close-btn" onClick={showNavBar}>
            <FaTimes />
          </button>
        </nav>

        <button className="nav-btn" onClick={showNavBar}>
          <FaBars />
        </button>

        <a href="tel:+ 1 (414) 109 2036" className="contact-button-container">
          <button className="contact-button">+ 1 (414) 109 2036</button>
        </a>
      </header>

      {screenWidth > 1024 && (
        <div
          className="navbar-drop-down-container"
          onMouseLeave={closeDropDown}
          style={{ display: `${showDropDown ? "" : "none"}` }}
        >
          <div className="navbar-drop-down-container-column-one">
            <Link to="/services/websiteDesignAndDevelopment">
              <h3>Website Design & Development</h3>
            </Link>

            <Link
              to="/services/customwebsitedesign"
              className="sub-category-one"
            >
              <CgWebsite color="#612d91" />

              <p>Custom Website Design</p>
            </Link>

            <Link
              to="/services/wordpresswebsitedsign"
              className="sub-category-two"
            >
              <SiWordpress color="#612d91" />

              <p>WordPress Website Design</p>
            </Link>

            <Link to="/services/seowebsite" className="sub-category-three">
              <TbSeo color="#612d91" />

              <p>SEO Website</p>
            </Link>

            <Link to="/services/ecommercewebsite" className="sub-category-four">
              <HiOutlineShoppingCart color="#612d91" />

              <p>E-commerce website design</p>
            </Link>
          </div>

          <div className="navbar-drop-down-container-column-two">
            <Link to="/services/socialmediamarketing">
              <h3>Social Media Marketing</h3>
            </Link>

            <Link
              to="/services/socialmediamarketing/socialmediaadvertising"
              className="sub-category-six"
            >
              <FaAdversal color="#612d91" />

              <p>Social Media Advertising</p>
            </Link>

            <Link
              to="/services/socialmediamarketing/socialmediabranding"
              className="sub-category-seven"
            >
              <AiTwotoneShop color="#612d91" />

              <p>Social Media Branding</p>
            </Link>

            <Link
              to="/services/socialmediamarketing/socialmediamanagement"
              className="sub-category-five"
            >
              <FaUsers color="#612d91" />

              <p>Social Media Management</p>
            </Link>
          </div>

          <div className="navbar-drop-down-container-column-three">
            <Link to="/services/graphicdesign">
              <h3>Graphic Design</h3>
            </Link>

            <Link
              to="/services/graphicdesign/bookcoverdesign"
              className="sub-category-eight"
            >
              <GiSecretBook color="#612d91" />

              <p>Book Cover Design</p>
            </Link>

            <Link
              to="/services/graphicdesign/designstationary"
              className="sub-category-nine"
            >
              <SiInteractiondesignfoundation color="#612d91" />

              <p>Stationary Design</p>
            </Link>

            <Link
              to="/services/graphicdesign/logodesign"
              className="sub-category-ten"
            >
              <RxIconjarLogo color="#612d91" />

              <p>Logo Design</p>
            </Link>

            <Link
              to="/services/graphicdesign/uiuxdesign"
              className="sub-category-twelve"
            >
              <IoIosColorPalette color="#612d91" />

              <p>UI-UX Design</p>
            </Link>

            <Link
              to="/services/graphicdesign/socialmediapostdesign"
              className="sub-category-eleven"
            >
              <MdCampaign color="#612d91" />

              <p>Social Media Post Design</p>
            </Link>
          </div>

          <div className="navbar-drop-down-container-column-four">
            <Link to="/services/videomarketingservices">
              <h3>Video Animation</h3>
            </Link>

            <Link
              to="/services/videomarketingservices/animateddesign"
              className="sub-category-thirteen"
            >
              <AiFillRocket color="#612d91" />

              <p>Animated Design</p>
            </Link>

            <Link
              to="/services/videomarketingservices/animatedproductpromoads"
              className="sub-category-fourteen"
            >
              <TbBrandFlightradar24 color="#612d91" />

              <p>Promo Ads</p>
            </Link>

            <Link
              to="/services/videomarketingservices/socialmediareels"
              className="sub-category-fifteen"
            >
              <BsCameraReelsFill color="#612d91" />

              <p>Social Media Reels</p>
            </Link>

            <Link
              to="/services/videomarketingservices/explaineranimations"
              className="sub-category-fifteen"
            >
              <DiCodeigniter color="#612d91" />

              <p>Explainer Animations</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
