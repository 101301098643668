import React, { useState, useEffect } from "react";

import { useInView } from "react-intersection-observer";

import { Link } from "react-router-dom";

import "./Skills.css";

export default function Skills() {
  const [refProgress, inViewProgress] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (inViewProgress) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 88) {
            clearInterval(timer);
          }
          return prevProgress + 1;
        });
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inViewProgress]);

  const [refDevelopment, inViewDevelopment] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [development, setDevelopment] = useState(0);

  useEffect(() => {
    if (inViewDevelopment) {
      const timer = setInterval(() => {
        setDevelopment((prevProgress) => {
          if (prevProgress >= 76) {
            clearInterval(timer);
          }
          return prevProgress + 1;
        });
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inViewDevelopment]);

  const [refMarketing, inViewMarketing] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [marketing, setMarketing] = useState(0);

  useEffect(() => {
    if (inViewMarketing) {
      const timer = setInterval(() => {
        setMarketing((prevProgress) => {
          if (prevProgress >= 93) {
            clearInterval(timer);
          }
          return prevProgress + 1;
        });
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inViewMarketing]);

  return (
    <div className="home-skills-container">
      <div className="home-skills-1">
        <h1>OUR SKILLS</h1>

        <br />

        <p>
          As a devoted digital marketing agency in the USA, our skills are
          finely honed in various aspects of the industry. We specialize in
          providing exceptional services as a social media marketing agency,
          excelling in strategies for Facebook business marketing and other
          social media platforms. We possess a diverse range of proficiencies
          across various digital marketing disciplines, surpassing the scope of
          social media marketing. With our extensive repertoire of skills, we
          can elevate your online visibility, amplify brand recognition, and
          achieve exceptional outcomes for your business.
        </p>

        <br />

        <Link to="tel:8882192787">Call Now</Link>
      </div>

      <div className="home-skills-2">
        <div>
          <h1>Design</h1>
          <div className="home-progress-bar-container">
            <div
              className="home-progress-bar"
              style={{ width: `${progress}%` }}
              ref={refProgress}
            >
              {progress}%
            </div>
          </div>
        </div>

        <div>
          <h1>Development</h1>
          <div className="home-progress-bar-container">
            <div
              className="home-progress-bar"
              style={{ width: `${development}%` }}
              ref={refDevelopment}
            >
              {development}%
            </div>
          </div>
        </div>

        <div>
          <h1>Marketing</h1>
          <div className="home-progress-bar-container">
            <div
              className="home-progress-bar"
              style={{ width: `${marketing}%` }}
              ref={refMarketing}
            >
              {marketing}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
