import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import DigitalMarketingTwo from "../components/home_page_components/digital_marketing_services/DigitalMarketingTwo";

import Card1 from "../components/home_page_components/cards/Card1";
import Summary from "../components/home_page_components/summary/Summary";
import Carousel from "../components/home_page_components/carousel/Carousel";
import DigitalMarektingInUsa from "../components/home_page_components/digital_marketing_in_USA/DigitalMarekting";
import Skills from "../components/home_page_components/ourskills/Skills";
import Footer from "../components/genral_components/Footer/Footer";
import Ourwork from "../components/home_page_components/ourwork/Ourwork";

import Services1 from "../components/home_page_components/services/Services1";

import Navbar from "../components/genral_components/Navbar/Navbar";

import BannerAgain from "../components/home_page_components/banner/BannerAgain";

import NavbarTwo from "../components/genral_components/Navbar/NavbarTwo";
import BannerTwo from "../components/home_page_components/banner/BannerTwo";
import DigitalMarekting from "../components/home_page_components/digital_marketing_in_USA/DigitalMarekting";

import CaseStudy from "../components/home_page_components/case-study-easy-imports/CaseStudy";
import SummaryTwo from "../components/home_page_components/summary/SummaryTwo";
import CarouselTwo from "../components/home_page_components/carousel/CarouselTwo";
import FeaturedBlog from "../components/home_page_components/featured-blog/FeaturedBlog";
import ContactUs from "../components/home_page_components/contact-us/ContactUs";


import FooterTwo from "../components/genral_components/Footer/FooterTwo";
import AllPricingPlans from "../components/home_page_components/all-pricing-plans/AllPricingPlans";

export default function Homepage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="w-screen overflow-hidden">
      {/* <NavbarTwo/> */}
      
      <Navbar />

      <BannerAgain />

      {/* <BannerTwo/> */}

      <DigitalMarketingTwo/>

      <CaseStudy/>

      {/* <DigitalMarketing /> */}

      {/* <br />
      <br />*/}

      {/* <Card1 /> */} 

      {/* <SummaryTwo/> */}
      <Summary />

      <br/>
      <br/>

      <Services1 />

      {/* <AllPricingPlans/> */}

      {/* <Carousel /> */}
      <DigitalMarektingInUsa />

      <Skills />

      <Carousel/>
      <br/>
      <br/>
      {/* <CarouselTwo/> */}

      <Ourwork />

      {/* <FeaturedBlog/> */}

      {/* <ContactUs/> */}

      <Footer />
      {/* <FooterTwo/> */}
      
    </div>
  );
}
