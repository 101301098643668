import React from "react";

import Triangle from "./Triangle.svg";

export default function DigitalMarketingTwo() {
  return (
    <div className="relative mb-4">
      <div className="px-4 py-4 flex flex-col items-center justify-center">
        <h1 className="text-[#612D91] text-center font-bold text-2xl mb-2">
          Digital Marketing Service
        </h1>

        <p className="text-sm text-center md:w-96 lg:w-[500px]">
          Our skilled team excels in providing all-encompassing digital
          marketing services to meet the precise demands of your business,
          including Search Engine Optimization (SEO), Pay-Per-Click (PPC)
          advertising, online advertisings.
        </p>

        <img
          src={Triangle}
          className="absolute top-4 w-20 left-8 hidden md:block "
        />
      </div>

      <div className="py-4 bg-[#F8F8F8] flex flex-col gap-4 md:flex-row md:justify-center md:items-start lg:gap-6">
        <div className="flex flex-col justify-center items-center md:gap-1">
          <picture className="w-12 aspect-square">
            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_50/v1692361147/HomePagePictures/HomePageCards/Social-Media-normal_cixkom.webp"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692361147/HomePagePictures/HomePageCards/Social-Media-normal_cixkom.webp"
              alt="social media"
              className="w-full h-auto"
            />
          </picture>

          <h1 className="text-sm text-center w-36 lg:w-40 lg:text-base">Social Media Marketing</h1>
        </div>

        <div className="flex flex-col justify-center items-center md:gap-1">
          <picture className="w-12 aspect-square">
            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_50/v1692361181/HomePagePictures/HomePageCards/website-normal_m0pa0o.png"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692361181/HomePagePictures/HomePageCards/website-normal_m0pa0o.png"
              alt="website"
              className="w-full h-auto"
            />
          </picture>

          <h1 className="text-sm text-center w-36 lg:w-40 lg:text-base">
            Website Design & Development
          </h1>
        </div>

        <div className="flex flex-col justify-center items-center md:gap-1">
          <picture className="w-12 aspect-square">
            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_50/v1692361183/HomePagePictures/HomePageCards/video-normal_ctj0tq.webp"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692361183/HomePagePictures/HomePageCards/video-normal_ctj0tq.webp"
              alt="video marketing services in USA"
              className="w-full h-auto"
            />
          </picture>

          <h1 className="text-sm text-center w-36 lg:w-40 lg:text-base">
            Video Animation/Editing Or Promo Ads
          </h1>
        </div>

        <div className="flex flex-col justify-center items-center md:gap-1">

          <picture className="w-12 aspect-square">
            <source
              media="(max-width: 450px)"
              srcSet="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto,w_50/v1692361143/HomePagePictures/HomePageCards/pen-normal_qv2qdf.webp"
            />

            <img
              src="https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692361143/HomePagePictures/HomePageCards/pen-normal_qv2qdf.webp"
              alt="video marketing services in USA"
              className="w-full h-auto"
            />
          </picture>

          <h1 className="text-sm text-center w-36 lg:w-40 lg:text-base">Graphic Designing</h1>

        </div>
      </div>
    </div>
  );
}
