import React, { useState , useEffect } from "react";

import { useInView } from "react-intersection-observer";

import "./DigitalMarketing.css";

import { useNavigate } from "react-router-dom";

export default function DigitalMarekting() {

  const navigate = useNavigate();

  const [refCount, inViewCount] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (inViewCount) {

      const targetCount = 52;
      const duration = 1000; 
      const delay = 10; 
      const steps = duration / delay;
      const increment = targetCount / steps;

      let currentCount = 0;
      let counter = setInterval(() => {
        currentCount += increment;
        setCount(Math.round(currentCount));

        if (currentCount >= targetCount) {
          setCount(targetCount);
          clearInterval(counter);
        }
      }, delay);

      return () => {
        clearInterval(counter);
      };
    }
  }, [inViewCount]);

  const [refClients, inViewClients] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [clients, setClients] = useState(0);
 
  useEffect(() => {
    const targetCount = 38;
    const duration = 1000; 
    const delay = 10; 
    const steps = duration / delay;
    const increment = targetCount / steps;

    let currentCount = 0;
    let counter = setInterval(() => {
      currentCount += increment;
      setClients(Math.round(currentCount));

      if (currentCount >= targetCount) {
        setClients(targetCount);
        clearInterval(counter);
      }
    }, delay);

    return () => {
      clearInterval(counter);
    };
  }, [inViewClients]);

  const [refCoffee, inViewCoffee] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const [coffee, setCoffee] = useState(0);

  useEffect(() => {
    const targetCount = 79;
    const duration = 1000; 
    const delay = 10; 
    const steps = duration / delay;
    const increment = targetCount / steps;

    let currentCount = 0;
    let counter = setInterval(() => {
      currentCount += increment;
      setCoffee(Math.round(currentCount));

      if (currentCount >= targetCount) {
        setCoffee(targetCount);
        clearInterval(counter);
      }
    }, delay);

    return () => {
      clearInterval(counter);
    };
  }, [inViewCoffee]);

  // Track when the container one is in view and then only load the background image.
  //This is an attempt to speed up the initial page load 
  const [refContainerOne , inViewContainerOne] = useInView();
  const [backgroundImageLoaded , setBackgroundImageLoaded] = useState(false);

  useEffect(() => {
    if (inViewContainerOne && !backgroundImageLoaded) {
      const image = new Image();
      image.src = "https://res.cloudinary.com/dx3u24xir/image/upload/f_auto,q_auto/v1692437074/HomePagePictures/DigitalMarketingInUsa/loudspeaker3_nyn0un.webp";
      image.onload = () => {
        setBackgroundImageLoaded(true);
      };
    }
  }, [inViewContainerOne , backgroundImageLoaded]);

  return (
    <>
      <div className={`container-1 ${backgroundImageLoaded ? "bg-loaded" : ""} mt-8`} ref={refContainerOne}>
        
        <h1>Digital Marketing In Usa</h1>

        <p>
          Are you seeking captivating and dynamic website designs in the USA to
          promote your brand? Your search ends here! We'll develop tailor-made
          designs that capture the essence of your brand, leaving a lasting
          impression on your target audience.
        </p>

        <button onClick={() => {
           navigate("/contactus");
        }}>Contact Now</button>

      </div>

      <div className="digital-marketing-container-2"></div>

      <br />
      <br />

      {/* <div className="casestudy-container  mb-8">
        <div>
          <h3>Case Study</h3>

          <h2>Easy Import</h2>
        </div>

        <div>
          <h1 ref={refCount}>{count}</h1>

          <h5>Business Development</h5>
        </div>

        <div>
          <h1 ref={refClients} >{clients}</h1>

          <h5>Clients</h5>
        </div>

        <div>
          <h1 ref={refCoffee} >{coffee}</h1>

          <h5>Coffee's Drank</h5>
        </div>
      </div> */}

      
    </>
  );
}
