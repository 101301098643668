import React from "react";

import "./BannerAgain.css";

import { Link } from "react-router-dom";

export default function BannerAgain() {
  return (
    <div className="video-background">

      <video
        autoPlay
        muted
        loop
        id="my-video"
        loading="lazy"
        poster="./HomePageMainBanner.webp"
      >

        <source
          src="https://res.cloudinary.com/dx3u24xir/video/upload/v1692619055/HomePagePictures/Home-page-banner/statue2_kqu0xo.webm"
          type="video/webm"
        />
        
      </video>

      

      <div className="content">
        <h1>
          DIGITAL MARKETING <br /> AGENCY IN THE
          <br /> USA
        </h1>

        <h2>
          Empowering businesses to flourish <br /> in the digital realm
        </h2>

        <Link to="/contactus">
          <button>Let's chat</button>
        </Link>
      </div>
    </div>
  );
}
